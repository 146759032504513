import React from "react"
import { FadeInStaggered } from "../../animations/FadeIn"

export enum FontType {
  serif = "font-serif",
  sans = "font-sans",
  base = "",
}

interface Props {
  content: string | JSX.Element
  type?: FontType
  extraClasses?: string
  fontSizeClasses?: string
  fadeIn?: boolean
}

export const TextContent: React.FC<Props> = ({
  content,
  type = FontType.serif,
  fontSizeClasses = "text-lg sm:text-xl tracking-tight",
  extraClasses,
  fadeIn,
}) => {
  const result = (
    <h3 className={`${type} ${fontSizeClasses} ${extraClasses}`}>{content}</h3>
  )

  return fadeIn ? <FadeInStaggered>{result}</FadeInStaggered> : result
}

export const BaseTextContent: React.FC<Props> = ({
  content,
  type = FontType.base,
  extraClasses,
}) => <p className={`${type} ${extraClasses}`}>{content}</p>
