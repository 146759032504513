import React from "react"
import { Section } from "../../../components/atoms/Section"
import { Title } from "../../../components/atoms/Title"
import { useLang } from "../../../components/Header/LanguageToggle/useLang"
import { Languages } from "../../../provider"
import { useIsMobile } from "../../../components/hooks/useDimensions"

const Text = {
  [Languages.EN]: (
    <p className="mt-4 font-serif text-xl leading-tight sm:text-2xl">
      We believe there is a big difference between merely filling an existing
      position and finding the right match that transforms careers and
      organizations. VantagePoint’s purpose is to add tangible value to our
      clients and candidates alike.
    </p>
  ),
  [Languages.JP]: (
    <p className="mt-4 text-lg tracking-tighter font-jp-serif">
      欠員補充のための守りの採用と、組織を変革することが出来る方の攻めの採用の間には大きな違いがあります。当社のサービスは、単に候補者の履歴書を企業に提案するような従来のサービスとは異なり、あらゆる角度から人材を見極め、貴社の経営課題全体を最適化する人材を紹介するサービスを提供いたします。
    </p>
  ),
}

const Copy = () => {
  const lang = useLang()

  return (
    <div className="z-10 max-w-6xl text-gray-100">
      <Title>
        <span className="leading-none tracking-normal text-gray-100">
          Human Capital Mindset
        </span>
      </Title>
      {Text[lang]}
    </div>
  )
}

const HeroText = () => {
  const lang = useLang()
  const isMobile = useIsMobile()
  return (
    <Section fullHeight>
      <div
        className="z-10 flex flex-col justify-end pb-24"
        style={{ height: 750, marginTop: -750 }}
      >
        <div className="flex flex-col justify-between w-full sm:flex-row">
          {/* this is done in order to match responsive width */}
          <div className="opacity-0">
            <Title>
              <span>Our Story</span>
            </Title>
          </div>

          <div
            className="w-full mt-6 sm:px-6 sm:mt-0"
            style={{ width: isMobile ? "100%" : 944 }}
          >
            <Copy />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default HeroText
