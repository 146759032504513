import React from "react"
import AboutUs from "../pageComponents/AboutUs"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageProp } from "."

const AboutUsPage: React.FC<PageProp> = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="About Us" />
      <AboutUs />
    </Layout>
  )
}

export default AboutUsPage
