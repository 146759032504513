import React from "react"
import { Section } from "../../../components/atoms/Section"
import { Title } from "../../../components/atoms/Title"
import { TextContent, FontType } from "../../../components/atoms/TextContent"
import { useLang } from "../../../components/Header/LanguageToggle/useLang"
import { Languages } from "../../../provider"
import { useIsMobile } from "../../../components/hooks/useDimensions"

const content = {
  [Languages.EN]: (
    <>
      <TextContent
        fadeIn
        extraClasses="leading-snug"
        content="VantagePoint was established in 2012 with the firm belief that a new style of recruitment is needed. From up and coming talent to senior management roles across consulting firms, financial institutions and international corporations. Our Tokyo office provides comprehensive recruitment services tackling a wide range of human capital issues for our clients, delivering a high-touch, 100% bespoke approach rather than a one size fits all service."
      />
      <br />
      <TextContent
        fadeIn
        extraClasses="leading-snug"
        content="In 2020 VantagePoint has taken an exciting leap forward by joining forces with JAC Recruitment Co. Ltd., one of the largest recruitment consultancies in Japan operating the “360-degree” style service, whereby each consultant supports both companies’ and candidates’ needs. Our collective ambition is to offer the expertise, scale, and culture that our customers are looking for; while at the same time staying true to our flexible, innovative, impact-driven way of working."
      />
    </>
  ),
  [Languages.JP]: (
    <>
      <TextContent
        fadeIn
        type={FontType.base}
        extraClasses="leading-snug sm:leading-normal tracking-tighter mb-2"
        fontSizeClasses="text-md sm:text-lg"
        content="バンテージポイントは、新しいスタイルの人材紹介会社が必要であるという信念と共に、2012年に創業されました。"
      />
      <TextContent
        fadeIn
        type={FontType.base}
        extraClasses="leading-snug sm:leading-normal tracking-tighter mb-2"
        fontSizeClasses="text-md sm:text-lg"
        content="東京に拠点を置き、アジアで活動するグローバルなコンサルティングファームや金融機関に対して、将来有望な若い才能から即戦力となる高度な管理職まで、さまざまなニーズに対応する人材紹介サービスを提供しています。私たちは、キャリア戦略パートナーとして、完全オーダーメイド型の対応を行っています。"
      />
      <br />
      <TextContent
        fadeIn
        type={FontType.base}
        extraClasses="leading-snug sm:leading-normal tracking-tighter"
        fontSizeClasses="text-md sm:text-lg"
        content="そんな私たちは、2020年に、企業様と候補者の両方のニーズを満たすため、1人のコンサルタントが企業様と転職希望者の双方を支援する「両面型」のビジネスモデルでは日本最大級の人材紹介会社JAC Recruitmentと力を合わせることで、さらなる飛躍を遂げました。"
      />
      <br />
      <TextContent
        fadeIn
        type={FontType.base}
        extraClasses="leading-snug sm:leading-normal tracking-tighter"
        fontSizeClasses="text-md sm:text-lg"
        content="これからも、お客様が求める専門知識、ネットワーク、カルチャーを提供すると同時に、柔軟性と革新性を備え、インパクトを与えることを大切にするという信念に忠実であり続けます。"
      />
    </>
  ),
}

const OurStory = () => {
  const lang = useLang()
  const isMobile = useIsMobile()
  return (
    <Section>
      <div className="flex flex-col justify-between w-full bg-white sm:flex-row sm:-mb-16">
        <Title>
          <span>Our Story</span>{" "}
        </Title>
        <div
          className="w-full pb-6 mt-6 sm:px-6 sm:mt-0"
          style={{ width: isMobile ? "100%" : 944 }}
        >
          {content[lang]}
          <br />
        </div>
      </div>
    </Section>
  )
}

export default OurStory
