import React from "react"
import { Title } from "../atoms/Title"
import { Section, BG } from "../atoms/Section"
import { FadeInStaggered } from "../animations/FadeIn"
import { TextContent, FontType } from "../atoms/TextContent"
import { useStaticQuery, graphql } from "gatsby"
import { TheTeamCard } from "../atoms/Cards"
import { useLang } from "../Header/LanguageToggle/useLang"
import { Languages } from "../../provider"
import { useDimensions } from "../hooks/useDimensions"

interface Consultant {
  node: {
    frontmatter: any
    fields: {
      slug: any
    }
  }
}

export const TheTeam = () => {
  const lang = useLang()
  const { width } = useDimensions()
  const isNormal = width < 1600
  const isMedium = width < 1200
  const isMobile = width < 800
  const isTiny = width < 400

  let gridTemplateColumns
  if (isTiny) {
    gridTemplateColumns = "100%"
  } else if (isMobile) {
    gridTemplateColumns = "1fr 1fr"
  } else if (isMedium) {
    gridTemplateColumns = "1fr 1fr 1fr"
  } else if (isNormal) {
    gridTemplateColumns = "1fr 1fr 1fr 1fr"
  } else {
    gridTemplateColumns = "1fr 1fr 1fr 1fr 1fr"
  }

  const data = useStaticQuery(graphql`
    query TheTeamQuery {
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: ASC }
        filter: {
          fileRelativePath: { regex: "/content/consultants/" }
          parent: {}
        }
      ) {
        edges {
          node {
            frontmatter {
              date
              consultant
              position
              location
              image {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            fields {
              slug
            }
            ...TinaRemark
          }
        }
      }
    }
  `)

  const Consultants = data.allMarkdownRemark.edges

  const content = {
    [Languages.EN]: (
      <TextContent
        fadeIn
        content="VantagePoint is a consultancy that combines a high level of IQ, EQ, and a deep understanding of your industry. Our team brings a wealth of experience in recruiting, consulting, banking, securities and global businesses. We possess an in-depth understanding of your recruitment needs, the technical details, culture fit, and are best positioned to advise on which candidate is the right fit for your organization."
      />
    ),
    [Languages.JP]: (
      <>
        <TextContent
          fadeIn
          type={FontType.base}
          extraClasses="leading-snug sm:leading-normal tracking-tighter"
          fontSizeClasses="text-md sm:text-lg"
          content="リクルーティング・経営コンサルティング・銀行・証券をはじめとしたグローバル企業出身で、高いレベルのIQとEQを兼ね備えたキャリアコンサルタントが、企業様の採用ニーズを深く理解し、専門性と精度の高い人材紹介サービスを通じて貴社を支援致します。"
        />
        <TextContent
          fadeIn
          type={FontType.base}
          extraClasses="leading-snug sm:leading-normal tracking-tighter"
          fontSizeClasses="text-md sm:text-lg"
          content="弊社のコンサルタントはー人ひとりが自らの専門領域と、それに関連する職種についての情報収集・分析、ネットワーク作りを日々行っており、お客様に有益な情報を提供できるよう心がけています。"
        />
      </>
    ),
  }

  return (
    <Section bg={BG.gray} id="theteam">
      <div className="flex flex-col justify-between -mb-16 sm:flex-row">
        <FadeInStaggered>
          <Title>
            <span>The Team</span>
          </Title>
        </FadeInStaggered>

        <div
          className="pb-12 mt-6 sm:px-6 sm:mt-0"
          style={{ width: isMobile ? "100%" : 944 }}
        >
          {content[lang]}
        </div>
      </div>

      <div
        className="mt-16 mb-32"
        style={{
          display: "grid",
          gridTemplateColumns: gridTemplateColumns,
          gridGap: isMobile ? 15 : 25,
        }}
      >
        {Consultants.map((consultant: Consultant, i: number) => {
          const data = consultant.node.frontmatter
          const slug = consultant.node.fields.slug

          let fluid
          try {
            fluid = data.image.childImageSharp.fluid
          } catch (e) {
            fluid = ""
          }

          return (
            <TheTeamCard
              fadeIn
              key={i}
              name={data.consultant}
              subtitle={`${data.position}, ${data.location}`}
              fluid={fluid}
              slug={slug}
              mobile={isMobile}
            />
          )
        })}
      </div>
    </Section>
  )
}
