import React from "react"
import Hero from "./Hero"
import { CallToAction } from "../../components/CallToAction"
import Awards from "../../components/Awards"
import { TheTeam } from "../../components/TheTeam"
import OurStory from "./OurStory"
import { useLang } from "../../components/Header/LanguageToggle/useLang"
import { Languages } from "../../provider"
import { awardsList } from "../../constants"
import { EmailType } from "../../utils/sendEmail"
import HeroText from "./HeroText"

const Page = () => {
  const lang = useLang()
  return (
    <div>
      <Hero />
      <HeroText />
      <OurStory />
      <TheTeam />
      <Awards awardsList={awardsList[lang]} />
      <CallToAction
        title="Need Help?"
        text={
          lang === Languages.EN
            ? "Enter your details and we will get in touch soon."
            : "お問合せ内容のご記入をお願いします。"
        }
        type={EmailType.default}
      />
    </div>
  )
}

export default Page
