import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { useDimensions } from "../../../components/hooks/useDimensions"
import { WIDTH_BREAKPOINT } from "../../../constants"

const Image: React.FC<{ mobile?: boolean }> = ({ mobile }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "003_w2000.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <div className="absolute bottom-0 left-0 z-0 w-full h-full">
      <Img
        fluid={data.placeholderImage.childImageSharp.fluid}
        style={{ top: mobile ? 0 : -200 }}
        className={mobile ? "h-full" : ""}
      />
    </div>
  )
}

const Hero = () => {
  const { width } = useDimensions()
  const isSmall = width < 1100
  const isMobile = width < WIDTH_BREAKPOINT
  return (
    <div
      className="relative flex items-end justify-end overflow-hidden sm:pr-16 sm:pb-20"
      style={{
        transition: "height 300ms ease-in",
        height: isMobile ? "600px" : "750px",
      }}
    >
      {/* <HeroText /> */}
      <Image mobile={isSmall} />
    </div>
  )
}

export default Hero
